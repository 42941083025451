import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Container from '../components/Container'
import Slider from "react-slick"
import BackgroundImage from 'gatsby-background-image'

const FlexContainer = styled.div`
  margin-top: 50px;
  display: flex;
  font: 25px bold;
  flex-wrap: wrap;
  align-items: start;
  justify-content: center;
`

const DivBgImg2Parent = styled.div`
  width: 300px;
  height: 300px;
  margin: 10px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  overflow: hidden;
  a {
  text-decoration: none;
  color: inherit; 
  }
`

const BgImg2 = styled(BackgroundImage) `
height: 100%;
width: 100%;
line-height: 300px;
text-align: center;
.span {
display: inline-block;
vertical-align: middle;
line-height: normal;
}
`

const TextDiv = styled.div`
  padding-top: 20px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 20pt;
  font-weight:  bold;
`
const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1
}

const Slider2 = styled(Slider) `
  background-color: #F8F8F8;
`

const PostTemplate = ({ data, pageContext }) => {
    const {
        name,
        heroImage,
        description,
        priceInRm,
        slug,
        recipes,
        shelfLife
    } = data.contentfulKiwimProducts
    const postNode = data.contentfulKiwimProducts
    const openLink = (slug) => {
        location.href = `/blog/${slug}`;
    }
    return (
        <Layout>
            <Helmet>
                <title>{`${name}`}</title>
            </Helmet>
            <FlexContainer>
                <Img fluid={heroImage.fluid} backgroundColor={'#eeeeee'} style={{width:"50%", minWidth:"300px", maxWidth:"700px"}} >
                </Img>
                <div style={{margin:"10px"}}>
                    <span style={{ fontSize: "1.2em", fontWeight: "bold" }}>{name}</span>
                    <hr style={{ borderColor: "gold", "background-color": "gold"}}/>
                    <br /><br />
                    <span>RM{priceInRm}</span><br />
                    <span>shelf life: {shelfLife}</span><br /><br />
                    <hr style={{ borderColor: "gold", "background-color": "gold" }}/>
                    <span style={{ color: "grey" }}>{description}</span><br />                   
                </div>
                </FlexContainer>
                              
            {recipes && (<TextDiv>
                <span> Try out {name} in these recipes </span>
            </TextDiv>)
            }
            {recipes && (
                < Slider2 {...settings} className="overflow-hidden">
                    {recipes.map((post) => (
                        <DivBgImg2Parent>
                                <BgImg2 fluid={post.heroImage.fluid} backgroundColor={'#eeeeee'} >
                                    <span> {post.title} </span>
                                <button onClick={() => openLink(post.slug)}
                                    style={{height: "30px", backgroundColor: "white", color: "black", borderRadius: "10px", font: "20px Arial", fontWeight:"bold"}}
                                    value="Try it out">
                                    Try it out
                                </button>
                                </BgImg2>
                        </DivBgImg2Parent>
                    ))}
                </Slider2>)}
        </Layout>
    )
}

export const query = graphql`
  query($slug: String!) {
    contentfulKiwimProducts(slug: { eq: $slug }) {
      recipes {
        title
        body {
          body
        }
        heroImage {
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
        }
        slug
      }
      heroImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        ogimg: resize(width: 1800) {
          src
          width
          height
        }
      }
      slug
      name
      description
      priceInRm
      shelfLife
    }
  }
`

export default PostTemplate
